html{
  background-color: #3a3a3a;
}

.center {
  margin: auto;
  margin-top: 20%;
  /* width: 50%; */
  width: 450px;
  /* border: 3px solid green; */
  padding: 10px;
}