.title{
  color: black;
  margin: 0px;
}

.countdownBox{
  align-items: center;
  text-align: center;
  vertical-align: middle;
  border: 3px solid black;
  /* box-shadow: 0px 0px 200px red; */
  background-color: rgb(175, 22, 53);
  border-radius: 500px;
  -webkit-animation: blink 1.0s linear infinite;
  -moz-animation: blink 1.0s linear infinite;
  -ms-animation: blink 1.0s linear infinite;
  -o-animation: blink 1.0s linear infinite;
  animation: blink 1.0s linear infinite;
  width: 450px;
  height: 100px;
}

@keyframes blink {
  0% { box-shadow: 0px 0px 200px darkred; }
  50% { box-shadow: none; }
  100% { box-shadow: 0px 0px 200px darkred; }
}

@-webkit-keyframes blink {
  0% { text-shadow: 0 0 15px darkred; }
  50% { text-shadow: 0 0 0; }
  100% { text-shadow: 0 0 15px darkred; }
}

h1{
  margin: 0px;
  margin-top: 25px;
}
